import {
  BOT_BACKGROUND,
  DIGITAL_HUMAN_LOADING,
  DIGITAL_HUMAN_SETTING,
  HYGEN_PEER_CONNECTION,
  HYGEN_RENDER_ID,
  HYGEN_SESSION_ID,
  HYGEN_SESSION_INFO,
  INIT_HYGEN_SESSION_ERROR,
  SESSION_INITIALIZED_SUCCESSFULLY
} from "./constants";
import { DigitalHumanSetting, DigitalHumanState } from "./interfaces";

export default {
  [DIGITAL_HUMAN_LOADING]: (
    state: DigitalHumanState,
    loading: boolean
  ): void => {
    state.loading = loading;
  },
  [BOT_BACKGROUND]: (state: DigitalHumanState, payload: boolean): void => {
    state.bot_background = payload;
  },
  [DIGITAL_HUMAN_SETTING]: (
    state: DigitalHumanState,
    payload: DigitalHumanSetting
  ): void => {
    state.bot_setting = payload;
  },
  [HYGEN_SESSION_ID]: (
    state: DigitalHumanState,
    payload: string | null
  ): void => {
    state.hygen_session_id = payload;
  },
  [HYGEN_SESSION_INFO]: (
    state: DigitalHumanState,
    payload: any | null
  ): void => {
    state.hygen_session_info = payload;
  },
  [HYGEN_RENDER_ID]: (state: DigitalHumanState, payload: number): void => {
    state.hygen_render_id = payload;
  },
  [HYGEN_PEER_CONNECTION]: (
    state: DigitalHumanState,
    payload: RTCPeerConnection | null
  ): void => {
    state.hygen_peer_connection = payload;
  },
  [INIT_HYGEN_SESSION_ERROR]: (
    state: DigitalHumanState,
    payload: string | null
  ): void => {
    state.init_hygen_session_error = payload;
  },
  [SESSION_INITIALIZED_SUCCESSFULLY]: (
    state: DigitalHumanState,
    payload: boolean
  ): void => {
    state.session_initialized_successfully = payload;
  }
};
